<script lang="ts" setup>
import type { BrandHomepage } from '@integration-layer/ampliance/schemas/brand-homepage-schema.localized'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: BrandHomepage
}>()

const data = await useComponentResponse<BrandHomepage>(props)

const components = computed(() =>
  connectComponents(
    // @ts-ignore
    data.value?.items.slotContent ?? [],
    props.locale,
    props.vse
  )
)
const seo = data.value?.seo
const seoExtraData: BrandHomepageMeta = {
  type: 'brandHomepage',
  slug: data.value?.urlSlug ?? '',
  // @ts-ignore
  brand: data.value?.parentPage?.categoryTitle,
}
if (seo)
  await useAmplienceSeo({
    // @ts-ignore
    deliveryId: seo._meta.deliveryId,
    content: seo,
    extraData: seoExtraData,
  })
</script>

<template>
  <div class="contents">
    <AmplienceDynamicContent :components></AmplienceDynamicContent>
  </div>
</template>
